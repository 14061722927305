import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img, { FixedObject } from 'gatsby-image'
import styled from 'styled-components';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const EcommerceBrandsSlider = ({ className }) => {
  const data = useStaticQuery ( graphql`
    query EcommerceBrandsSliderQuery {
      drop: file(name: { eq: "drop_logo" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      zmi: file(name: { eq: "zmi_logo" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      gaomon: file(name: { eq: "gaomon_logo" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      zulily: file(name: { eq: "zulily_logo" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      styrenic_solutions: file(name: { eq: "styrenic_solutions_logo" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      mind_pixels: file(name: { eq: "mind_pixels_logo" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `
  )

  // Set ImageData.
  const drop = data.drop?.childImageSharp?.fluid
  const zmi = data.zmi?.childImageSharp?.fluid
  const gaomon = data.gaomon?.childImageSharp?.fluid
  const zulily = data.zulily?.childImageSharp?.fluid
  const styrenic_solutions = data.styrenic_solutions?.childImageSharp?.fluid
  const mind_pixels = data.mind_pixels?.childImageSharp?.fluid

  const SliderContainer = styled.div`
        .slider-content {
            padding: 20px;
        }

        .slider-content .gatsby-image-wrapper {
          max-width: 110px;
          margin-left: auto;
          margin-right: auto;
        }

        @media only screen and (max-width: 981px) {
          .slider-content {
              padding-top: 10px;
          }
        }
    `;

  var settings = {
    speed: 1000,
    autoplaySpeed: 5000,
    fadeIn: false,
    autoplay: true,
    dots: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: true

        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true
        }
      }
    ]
  };

  return (
    <SliderContainer>
        <Slider {...settings}>
            <div className="slider-content">
                <Img fluid={drop} alt="Drop" />
            </div>
            <div className="slider-content">
                <Img fluid={zmi} alt="ZMI" />
            </div>
            <div className="slider-content">
                <Img fluid={gaomon} alt="Gaomon" />
            </div>
            <div className="slider-content">
                <Img fluid={zulily} alt="Zulily" />
            </div>
            <div className="slider-content">
                <Img fluid={styrenic_solutions} alt="Styrenic Solutions" />
            </div>
            <div className="slider-content">
                <Img fluid={mind_pixels} alt="Styrenic Solutions" />
            </div>
        </Slider>
    </SliderContainer>
  )
}

export default EcommerceBrandsSlider
import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img, { FixedObject } from 'gatsby-image'
import styled from 'styled-components';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const OurPartnersSlider = ({ className }) => {
  const data = useStaticQuery ( graphql`
    query OurPartnersSliderQuery {
      viwo: file(name: { eq: "viwo_logo" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      lozi: file(name: { eq: "lozi_logo" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      geodis: file(name: { eq: "geodis_logo" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      asendia: file(name: { eq: "asendia_logo" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      didadi: file(name: { eq: "didadi_logo" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      royal_mail: file(name: { eq: "royal_mail_logo" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      usps: file(name: { eq: "usps_logo" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `
  )

  // Set ImageData.
  const viwo = data.viwo?.childImageSharp?.fluid
  const lozi = data.lozi?.childImageSharp?.fluid
  const geodis = data.geodis?.childImageSharp?.fluid
  const asendia = data.asendia?.childImageSharp?.fluid
  const didadi = data.didadi?.childImageSharp?.fluid
  const royal_mail = data.royal_mail?.childImageSharp?.fluid
  const usps = data.usps?.childImageSharp?.fluid

  const SliderContainer = styled.div`
        .slider-content {
            padding: 20px;
        }

        .slider-content .gatsby-image-wrapper {
            max-width: 110px;
            margin-left: auto;
            margin-right: auto;
        }

        @media only screen and (max-width: 981px) {
            .slider-content {
                padding-top: 0;
            }
        }
    `;

  var settings = {
    speed: 1000,
    autoplaySpeed: 5000,
    fadeIn: false,
    autoplay: true,
    dots: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: true

        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true
        }
      }
    ]
  };

  return (
    <SliderContainer>
        <Slider {...settings}>
            <div className="slider-content">
                <Img fluid={viwo} alt="Viwo" />
            </div>
            <div className="slider-content">
                <Img fluid={lozi} alt="Lozi" />
            </div>
            <div className="slider-content">
                <Img fluid={geodis} alt="Geodis" />
            </div>
            <div className="slider-content">
                <Img fluid={asendia} alt="Asendia" />
            </div>
            <div className="slider-content">
                <Img fluid={didadi} alt="Didadi" />
            </div>
            <div className="slider-content">
                <Img fluid={royal_mail} alt="Royal Mail" />
            </div>
            <div className="slider-content">
                <Img fluid={usps} alt="USPS" />
            </div>
        </Slider>
    </SliderContainer>
  )
}

export default OurPartnersSlider